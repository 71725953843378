import PropTypes from 'prop-types';
import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import * as Ui from '@bgea-js/react-ui';
import { HeaderPage } from '@bgea-js/design-library';
import { SingleDonation } from '@bgea-js/form-templates';
import { Seo } from '../components/Seo';
import { useGiftOffer } from '../hooks/useGiftOffer';

function Page({ data }) {
  const oneTimeProjectIdList = [200085];
  const giftOffer = useGiftOffer();
  const page = data.page;
  const image = getImage(page.frontmatter?.image);
  // const mobile = getImage(page.frontmatter?.featuredMobile);
  let meta = {
    title: `Billy Graham Evangelistic Association | Giving | ${page.frontmatter.title}`,
    description: page.frontmatter.description,
    socialTitle: page.frontmatter.title,
  };
  let styles = {};
  const shouldUseRecurringToggle = !oneTimeProjectIdList.includes(
    page.frontmatter.projectCode,
  );

  return (
    <Layout
      meta={meta}
      styles={styles}
      header={
        <HeaderPage
          title={`Give to ${page.frontmatter.title}`}
          // subtitle="Your gift today will share the Good News with others and point them to the love of our Heavenly Father."
          imageWidth={4}
          className="is-hidden-mobile"
        >
          <GatsbyImage
            image={image}
            alt={page.frontmatter.featureAlt}
            className={`headerImage ${
              page.frontmatter.image ? 'desktopImage' : ''
            }`}
          />
          {page.frontmatter.featuredMobile ? (
            <GatsbyImage
              image={image}
              alt={page.frontmatter.featureAlt}
              className="headerImage mobileImage"
            />
          ) : (
            ''
          )}
        </HeaderPage>
      }
    >
      <Ui.Section className={`single-designation py-3`}>
        <Ui.Container>
          <Ui.Breadcrumb>
            <Ui.Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Ui.Breadcrumb.Item>
            <Ui.Breadcrumb.Item>
              <Link to="/giving-areas">Giving Areas</Link>
            </Ui.Breadcrumb.Item>
            <Ui.Breadcrumb.Item active>
              <Link to="#" aria-current="page">
                {page.frontmatter.title}
              </Link>
            </Ui.Breadcrumb.Item>
          </Ui.Breadcrumb>
        </Ui.Container>
      </Ui.Section>
      <Ui.Section className={` mb-6 py-0`}>
        <Ui.Container>
          <hr />
          <Ui.Columns>
            <Ui.Columns.Column>
              <p className="title">{page.frontmatter.bodyHeading}</p>
            </Ui.Columns.Column>
            <Ui.Columns.Column>
              <Ui.Content>
                <MDXRenderer>{data.page.body}</MDXRenderer>
              </Ui.Content>
            </Ui.Columns.Column>
          </Ui.Columns>
        </Ui.Container>
      </Ui.Section>
      <Ui.Section className="">
        <Ui.Container>
          <Ui.Columns centered={true}>
            <Ui.Columns.Column size={'two-thirds'}>
              <div id="singleDonation" className="pb-6">
                <SingleDonation
                  giftOffer={giftOffer}
                  donationOptions={[
                    {
                      value: page.frontmatter.title,
                      id: page.frontmatter.projectCode,
                    },
                  ]}
                  projectId={page.frontmatter.projectCode}
                  projectTitle={page.frontmatter.title}
                  showFreeGiftOffer={true}
                  recurringToggle={shouldUseRecurringToggle}
                  esubCodes={['BGEA', 'PLTR']}
                  showUpgradeGift={shouldUseRecurringToggle}
                  defaultDebitDay={'5th'}
                  disablePaypalGiftOffer
                  deliveryStatement={
                    '*While supplies last; please allow *4-6 weeks for delivery; U.S. residents only'
                  }
                />
              </div>
            </Ui.Columns.Column>
          </Ui.Columns>
        </Ui.Container>
      </Ui.Section>
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.shape({}),
};

export const pageQuery = graphql`
  query ($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      fields {
        slug
      }
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData
            fixed(width: 684, height: 385, quality: 75, cropFocus: CENTER) {
              height
              width
              src
            }
          }
          internal {
            mediaType
          }
          base
          relativePath
        }
        description
        bodyHeading
        projectCode
        title
      }
    }
  }
`;

export default Page;

export const Head = ({ location, params, data, pageContext }) => {
  // console.log('d',data)
  return (
    <Seo
      title={`Billy Graham Evangelistic Association | Giving | ${data.page.frontmatter.title}`}
      description={data.page.frontmatter.description}
      socialImage={data.page.frontmatter?.image?.childImageSharp?.fixed?.src}
      pathname={location.pathname}
    />
  );
};
