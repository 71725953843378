import { graphql, useStaticQuery } from "gatsby"

export const useGiftOffer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          giftOffer {
            title
            author
            description
            productCode
            image
          }
        }
      }
    }
  `)

  return data.site.siteMetadata.giftOffer
}